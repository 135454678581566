import { render, staticRenderFns } from "./zomerTab.vue?vue&type=template&id=b0e78dd8&scoped=true&"
import script from "./zomerTab.vue?vue&type=script&lang=js&"
export * from "./zomerTab.vue?vue&type=script&lang=js&"
import style0 from "./zomerTab.vue?vue&type=style&index=0&id=b0e78dd8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0e78dd8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCardText,VCardTitle,VCol,VMain,VNavigationDrawer,VRow,VSheet,VSwitch})
